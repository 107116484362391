<template>
    <div style="width: 100%;" v-loading="loading">
        <div style="width: 96%; margin-left:2%; height:380px; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">资金总览</p>
            <div>
                <table style="width: 100%; margin-top: -10px; line-height: 38px;">
                    <el-row :gutter="12" style="margin-left: 10px;">
                        <el-col :span="10">
                            <el-card shadow="always">
                                <el-row>
                                    <el-col :span="12">
                                        <div style="font-size: 20px;font-weight: bold;height: 120px;line-height: 50px; ">
                                            总余额
                                            <div style="font-size: 50px;font-weight: bold;color: red;">{{total.value1}}</div>
                                        </div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div class="div1" @click="withdraw()">提现</div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div class="div1" style="margin-top: 5px;" @click="recharge()">充值</div>
                                    </el-col>
                                </el-row>
                                <table class="ta">
                                    <tr>
                                        <th>不可用余额</th>
                                        <th>可提现金额</th>
                                        <th>提现中金额</th>
                                    </tr>
                                    <tr>
                                        <th style="color: red;">{{total.value2}}</th>
                                        <th style="color: red;">{{total.value3}}</th>
                                        <th style="color: red;">{{total.value4}}</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </table>
                            </el-card>
                        </el-col>
                    </el-row>
                </table>
            </div>
        </div>
        <div style="width: 96%; margin-left:2%; height:400px; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">资金明细</p>
            <div>
                <el-table :data="list"
                    :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                    <el-table-column prop="channel" label="渠道名称"></el-table-column>
                    <el-table-column prop="saldo" label="商户余额"></el-table-column>
                    <el-table-column prop="cantidad" label="可提现金额"></el-table-column>
                    <el-table-column prop="cantidadNo" label="不可用金额"></el-table-column>
                    <el-table-column prop="retirar" label="提现中金额"></el-table-column>
                    <el-table-column prop="jobName" :label="$t('operator')" width="200">
                        <template slot-scope="scope">
                            <el-tooltip style="margin-left: 10px;" class="item" effect="dark" content="提现">
                                    <el-button size="small" @click="withdraw()"  type="primary" icon="el-icon-upload2" circle plain></el-button>
                            </el-tooltip>
                            <el-tooltip v-if="scope.row.channel == 'STP' || scope.row.channel == 'toppay'" style="margin-left: 10px;" class="item" effect="dark" content="充值">
                                    <el-button size="small" @click="recharge()"  type="primary" icon="el-icon-download" circle plain></el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

    </div>
</template>
<script>
import apiurl from "@/api/public"
import _api from "@/api/index"
export default {
    data(){
        return{
            loading:false,
            total:{
                value1: 0,
                value2: 0,
                value3: 0,
                value4: 0,
            },
        }
    },
    created(){
        this.getList()
    },
    methods:{
        withdraw(){
            this.$router.push({path:"/withdraw",query:{}})
        },
        recharge(){
            this.$router.push({path:"/recharge",query:{}})
        },
        getList() {
            _api.get(apiurl.capital, {
            }).then(res => {
                this.list = res.data.list
                this.total.value1=res.data.value1
                this.total.value2=res.data.value2
                this.total.value3=res.data.value3
                this.total.value4=res.data.value4
            })
        },
    }
}
</script>
<style scoped>
    .ta {
        margin-top: 10px; 
        margin-bottom: 10px;                   
        line-height: 38px;
        width: 800px;
        text-align: left;
        width: 750px;
    }
    .div1 {
        width: 150px;
        height: 50px;
        line-height: 50px;
        background-color: aqua;
        border: 1px solid  black;
        text-align: center;
        font-size: 20px;
        border-radius: 5px;
        margin-left: 100px;
    }
</style>